<template>
  <tr>
    <td class="table__element-legend" colspan="7">
      {{ elementInitProperties.legend }}
    </td>
  </tr>

  <tr>
    <td class="tr__section">{{ element.fullElementName }}</td>

    <td class="tr__section">
      <OptionsBlockRotation
        :index="element.index"
        :model-value="name.rotations"
        @update:modelValue="updateElementProperty($event, 'rotations')"
      />
    </td>

    <td class="tr__section">
      <OptionsBlockUnderrotate
        :index="element.index"
        :model-value="name.underrotate"
        @update:modelValue="updateElementProperty($event, 'underrotate')"
      />
    </td>

    <td class="tr__section tr__section--edge">
      <OptionsBlockEdge
        :index="element.index"
        :is-button-options-show="isButtonOptionsEdgeShow"
        :model-value="name.edge"
        @update:modelValue="updateElementProperty($event, 'edge')"
      />
    </td>

    <td class="tr__section">
      <OptionsBlockGoe
        :index="element.index"
        :model-value="element.goe"
        @update:modelValue="updateElementGoe($event)"
      />
    </td>

    <td class="tr__section tr__section--scores">
      {{ scores }}
    </td>
  </tr>

  <tr class="tr--empty">
    <td class="tr__section"></td>
  </tr>
</template>

<script>
import guideRowMixin from '@/components/guide-value/guide-row-mixin';

import OptionsBlockRotation from '@/components/options/OptionsBlockRotation.vue';
import OptionsBlockUnderrotate from '@/components/options/OptionsBlockUnderrotate.vue';
import OptionsBlockGoe from '@/components/options/OptionsBlockGoe.vue';
import OptionsBlockEdge from '@/components/options/OptionsBlockEdge.vue';

export default {
  components: {
    OptionsBlockRotation,
    OptionsBlockUnderrotate,
    OptionsBlockEdge,
    OptionsBlockGoe,
  },

  mixins: [guideRowMixin],

  emits: ['update:modelValue'],

  computed: {
    isButtonOptionsEdgeShow() {
      return !this.name.lockEdge;
    },
  },

  methods: {
    setElementProperties() {
      this.name.rotations = 1;
    },
  },
};
</script>
