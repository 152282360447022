<template>
  <svg
    class="svg"
    width="5"
    height="1"
    viewBox="0 0 5 1"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0.5C0 0.223858 0.223858 0 0.5 0H4.5C4.77614 0 5 0.223858 5 0.5C5 0.776142 4.77614 1 4.5 1H0.5C0.223858 1 0 0.776142 0 0.5Z"
    />
  </svg>
</template>
