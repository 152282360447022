<template>
  <ButtonOptions>
    <IconPlus />
  </ButtonOptions>
</template>

<script>
import ButtonOptions from '@/components/buttons/ButtonOptions.vue';
import IconPlus from '@/components/icons/IconPlus.vue';

export default {
  components: { ButtonOptions, IconPlus },
};
</script>
