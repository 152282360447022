<template>
  <svg
    class="svg"
    width="5"
    height="5"
    viewBox="0 0 5 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 0C2.77614 0 3 0.223858 3 0.5V2H4.5C4.77614 2 5 2.22386 5 2.5C5 2.77614 4.77614 3 4.5 3H3V4.5C3 4.77614 2.77614 5 2.5 5C2.22386 5 2 4.77614 2 4.5V3H0.5C0.223858 3 0 2.77614 0 2.5C0 2.22386 0.223858 2 0.5 2H2V0.5C2 0.223858 2.22386 0 2.5 0Z"
    />
  </svg>
</template>
