<template>
  <div class="options" :class="animationClassesObj">
    <RadioBase
      v-for="(radio, index) in listRadio"
      :key="index"
      :name="name"
      :local-value="radio"
      :model-value="modelValue"
      @update:modelValue="$emit('update:modelValue', $event)"
    >
      {{ radio }}
    </RadioBase>
  </div>
</template>
<script>
import RadioBase from '@/components/checkbox/RadioBase.vue';
import optionsAnimationsMixin from '@/mixins/animations/options-animation-mixin';
export default {
  components: { RadioBase },

  mixins: [optionsAnimationsMixin],

  props: {
    listRadio: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    modelValue: {
      required: true,
    },
  },

  emits: ['update:modelValue'],
};
</script>
