<template>
  <div class="options" :class="mixClassesObj">
    <RadioBase
      v-for="(radio, index) in listRadio"
      :key="index"
      :name="name"
      :local-value="radio"
      :model-value="modelValue"
      @update:modelValue="$emit('update:modelValue', $event)"
    >
      {{ radio }}
    </RadioBase>
    <slot></slot>
  </div>
</template>
<script>
import { getCoords } from '@/utils/get-coords';

import RadioBase from '@/components/checkbox/RadioBase.vue';
import optionsAnimationsMixin from '@/mixins/animations/options-animation-mixin';
export default {
  components: { RadioBase },

  mixins: [optionsAnimationsMixin],

  props: {
    listRadio: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    modelValue: {
      required: true,
    },
    currentButton: {
      default: null,
    },
  },

  emits: ['options-is-opened', 'options-is-closed', 'update:modelValue'],

  data() {
    return {
      orientationTop: false,
      orientationBottom: false,
      orientationLeft: false,
      orientationRight: false,
    };
  },

  computed: {
    orientationClassesObj() {
      return {
        'options--top': this.orientationBottom,
        'options--bottom': this.orientationTop,
        'options--left': this.orientationRight,
        'options--right': this.orientationLeft,
      };
    },

    mixClassesObj() {
      return this.mixObj();
    },
  },

  watch: {
    orientationClassesObj: {
      deep: true,
      handler: function () {
        this.mixObj();
      },
    },

    animationClassesObj: {
      deep: true,
      handler: function () {
        this.mixObj();
      },
    },
  },

  methods: {
    openOptions() {
      this.optionsVisible = true;
      this.optionsOpening = true;
      this.currentButton !== null ? this.getOrientation() : false;
      this.handleAnimationsForwardEnd();
    },

    closeOptions() {
      this.optionsClosing = true;
      this.handleAnimationsBackwardEnd();
    },

    getOrientation() {
      this.resetOrientation();

      const { y, x } = this.countCurrentButtonCenter();
      const screenHorizontalCenter = document.documentElement.scrollHeight / 2;
      const screenVerticalCenter = document.documentElement.scrollWidth / 2;

      if (y < screenHorizontalCenter) {
        this.orientationBottom = true;
      } else {
        this.orientationTop = true;
      }

      if (x < screenVerticalCenter) {
        this.orientationRight = true;
      } else {
        this.orientationLeft = true;
      }
    },

    countCurrentButtonCenter() {
      const { top, left } = getCoords(this.currentButton);
      const y = this.currentButton.offsetHeight / 2 + top;
      const x = this.currentButton.offsetWidth / 2 + left;
      return { y, x };
    },

    resetOrientation() {
      this.orientationTop = false;
      this.orientationBottom = false;
      this.orientationLeft = false;
      this.orientationRight = false;
    },

    mixObj() {
      return Object.assign(
        this.animationClassesObj,
        this.orientationClassesObj
      );
    },
  },
};
</script>
