<template>
  <svg
    class="svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 1C12.5523 1 13 1.44772 13 2V14C13 14.5523 12.5523 15 12 15H4C3.44772 15 3 14.5523 3 14V2C3 1.44772 3.44772 1 4 1H12ZM4 0C2.89543 0 2 0.895431 2 2V14C2 15.1046 2.89543 16 4 16H12C13.1046 16 14 15.1046 14 14V2C14 0.895431 13.1046 0 12 0H4Z"
    />
    <path
      d="M4 2.5C4 2.22386 4.22386 2 4.5 2H11.5C11.7761 2 12 2.22386 12 2.5V4.5C12 4.77614 11.7761 5 11.5 5H4.5C4.22386 5 4 4.77614 4 4.5V2.5Z"
    />
    <path
      d="M4 6.5C4 6.22386 4.22386 6 4.5 6H5.5C5.77614 6 6 6.22386 6 6.5V7.5C6 7.77614 5.77614 8 5.5 8H4.5C4.22386 8 4 7.77614 4 7.5V6.5Z"
    />
    <path
      d="M4 9.5C4 9.22386 4.22386 9 4.5 9H5.5C5.77614 9 6 9.22386 6 9.5V10.5C6 10.7761 5.77614 11 5.5 11H4.5C4.22386 11 4 10.7761 4 10.5V9.5Z"
    />
    <path
      d="M4 12.5C4 12.2239 4.22386 12 4.5 12H5.5C5.77614 12 6 12.2239 6 12.5V13.5C6 13.7761 5.77614 14 5.5 14H4.5C4.22386 14 4 13.7761 4 13.5V12.5Z"
    />
    <path
      d="M7 6.5C7 6.22386 7.22386 6 7.5 6H8.5C8.77614 6 9 6.22386 9 6.5V7.5C9 7.77614 8.77614 8 8.5 8H7.5C7.22386 8 7 7.77614 7 7.5V6.5Z"
    />
    <path
      d="M7 9.5C7 9.22386 7.22386 9 7.5 9H8.5C8.77614 9 9 9.22386 9 9.5V10.5C9 10.7761 8.77614 11 8.5 11H7.5C7.22386 11 7 10.7761 7 10.5V9.5Z"
    />
    <path
      d="M7 12.5C7 12.2239 7.22386 12 7.5 12H8.5C8.77614 12 9 12.2239 9 12.5V13.5C9 13.7761 8.77614 14 8.5 14H7.5C7.22386 14 7 13.7761 7 13.5V12.5Z"
    />
    <path
      d="M10 6.5C10 6.22386 10.2239 6 10.5 6H11.5C11.7761 6 12 6.22386 12 6.5V7.5C12 7.77614 11.7761 8 11.5 8H10.5C10.2239 8 10 7.77614 10 7.5V6.5Z"
    />
    <path
      d="M10 9.5C10 9.22386 10.2239 9 10.5 9H11.5C11.7761 9 12 9.22386 12 9.5V13.5C12 13.7761 11.7761 14 11.5 14H10.5C10.2239 14 10 13.7761 10 13.5V9.5Z"
    />
  </svg>
</template>
