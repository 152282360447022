<template>
  <ButtonOptions>
    <IconMinus />
  </ButtonOptions>
</template>

<script>
import ButtonOptions from '@/components/buttons/ButtonOptions.vue';
import IconMinus from '@/components/icons/IconMinus.vue';

export default {
  components: { ButtonOptions, IconMinus },
};
</script>
