<template>
  <RadioBase
    :name="name"
    :local-value="localValue"
    :model-value="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <IconsThombUp />
  </RadioBase>
</template>

<script>
import RadioBase from '@/components/checkbox/RadioBase.vue';
import IconsThombUp from '@/components/icons/IconsThombUp.vue';

export default {
  components: { RadioBase, IconsThombUp },
  props: ['name', 'localValue', 'modelValue'],
  emits: ['update:modelValue'],
};
</script>
