<template>
  <svg
    class="svg"
    width="16"
    height="16"
    viewBox="0 -2 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 2.82757C1.88498 2.4578 3.1539 2.05944 4.38768 1.935C5.71799 1.80082 6.8464 1.99809 7.5 2.68671V12.4326C6.56511 11.9025 5.38065 11.8298 4.28732 11.9401C3.10693 12.0591 1.91771 12.4011 1 12.751V2.82757ZM8.5 2.68671C9.1536 1.99809 10.282 1.80082 11.6123 1.935C12.8461 2.05944 14.115 2.4578 15 2.82757V12.751C14.0823 12.4011 12.8931 12.0591 11.7127 11.9401C10.6194 11.8298 9.43489 11.9025 8.5 12.4326V2.68671ZM8 1.78306C7.01509 0.936466 5.58683 0.808974 4.28732 0.940049C2.77322 1.09277 1.24459 1.61235 0.293099 2.04484C0.114601 2.12598 0 2.30395 0 2.50002V13.5C0 13.67 0.0863761 13.8284 0.229307 13.9204C0.372238 14.0125 0.55214 14.0256 0.706901 13.9552C1.58875 13.5544 3.01012 13.074 4.38768 12.935C5.79565 12.793 6.97747 13.0223 7.60957 13.8124C7.70445 13.931 7.84811 14 8 14C8.15189 14 8.29555 13.931 8.39043 13.8124C9.02253 13.0223 10.2043 12.793 11.6123 12.935C12.9899 13.074 14.4113 13.5544 15.2931 13.9552C15.4479 14.0256 15.6278 14.0125 15.7707 13.9204C15.9136 13.8284 16 13.67 16 13.5V2.50002C16 2.30395 15.8854 2.12598 15.7069 2.04484C14.7554 1.61235 13.2268 1.09277 11.7127 0.940049C10.4132 0.808974 8.98491 0.936466 8 1.78306Z"
    />
  </svg>
</template>
